:root {
    --color-primary: #F3DF5E;
    --color-secondary: #707372;
    --color-text-default: var(--color-default);
    --color-text-muted: var(--color-dark-grey);

    --color-default: #282B34;
    --color-white: #FFF;
    --color-light-grey: #EFEFEF;
    --color-grey: #D2D2D2;
    --color-dark-grey: #8F8F8F;
    --color-dark: var(--color-default);

    --color-success: #28A745;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-primary-contrast: var(--color-default);
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #dac855;
    --color-secondary-dark: var(--color-text-default) /*-- changed from #262b47 --*/;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #fae561;
    --color-secondary-light: var(--color-secondary) /*-- changed from #47549C --*/;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --color-page-bg: #fffef7;

    --color-muted-grey: #c8c6c7;

    --font-default: "Overpass-Light", sans-serif;
    --font-default-weight: 400;
    --font-default-bold: "Overpass-ExtraBold", sans-serif;
    --font-default-bold-weight: 400;

    --font-size-default: 1rem;
}